@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

@font-face {
  font-family: 'Mail Sans Roman';
  src: url('./assets/fonts/MailSansRoman-Light.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Light.woff') format('woff');
  font-weight: 300; 
  font-style: normal;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-Regular.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src:local('Mail Sans Roman'), 
   url('./assets/fonts/MailSansRoman-Medium.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-Medium.woff') format('woff');
  font-weight: 500; /* Normal weight */
  font-style: normal;
}

@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-Bold.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-DemiBold.woff') format('woff');
  font-weight: 600; /* Normal weight */
  font-style: normal;
}


@font-face {
  font-family: 'Mail Sans Roman';
  src: local('Mail Sans Roman'), 
  url('./assets/fonts/MailSansRoman-DemiBold.woff2') format('woff2'),
       url('./assets/fonts/MailSansRoman-DemiBold.woff') format('woff');
  font-weight: 700; /* Normal weight */
  font-style: normal;
}


code {
  font-family: 'Mail Sans Roman', sans-serif;
  
}

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Mail Sans Roman', sans-serif;
  
}

body {
margin: 0;
background-color: #111111 !important;
color: #FFFFFF !important;
font-family:  'Mail Sans Roman', sans-serif;
overflow-x: hidden !important;



}

