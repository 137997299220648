

h1 {
  font-size: 58px !important;
  font-weight: 700;

  
}


h2 {
  font-size: 48px  !important;
  font-weight: 600 ;
}

h3 {
  font-size: 24px !important;
  /* font-weight: 500 ; */

  
}


h4 {
  font-size: 20px !important;
  font-weight: 400 ;
}



h5 {
  font-size: 18px !important;
  font-weight: 400 ;
}


h6 {
  font-size: 16px !important;
  font-weight: 400 ;
  
}

p {
  font-size: 14px !important;
  font-weight: 400 ;
}

.training-body * {
  font-family: 'Inter', sans-serif !important;
}
.form-control::placeholder{
  color: #E9EBF8 !important;
  padding-left: 5px;
  font-weight: 200 !important;
}
textarea::placeholder{
  color: #E9EBF8 !important;
  padding-left: 10px;
  font-weight: 200 !important;
}

.form-select{
  font-weight: 200 !important;
}





.nav-link{
  font-family: "Nunito Sans", sans-serif !important;
  color:#FFFFFF !important;
  font-weight: 700 !important;
  font-size: 16px !important;
line-height: 21.82px !important;
letter-spacing: 0.005em !important;


}
.nav-link.active {
  border-bottom: 5px solid #FFB74D !important;
}

 .nav-link:hover {

  border-style: solid !important;
  border-width: 0 0 3px 0 !important; 
  border-image: linear-gradient(to right, #406282, #FFB74D) 1 stretch !important;
}




.no .nav-link:hover .active{
  
  border-style: none !important;
  border-width:0!important; 
  border-image: none !important;
}

.my-app .btn-primary{

border-radius: 24px ;
background: linear-gradient(95.21deg, #406282 13.07%, #FFB74D 112.55%) ;
color: #F5F5F5 ;
font-family:  'Mail Sans Roman' ;
font-size: 18px ;
font-weight: 500 ;
line-height: 23.04px ;
letter-spacing: 0.005em ;
padding: 10px 24px ;
border: none ;
}

.position-absolute img {
  pointer-events: none ;
}

.custom-carousel .carousel-indicators .active {

background: linear-gradient(95.21deg, #406282 13.07%, #FFB74D 112.55%) !important;
color: black !important;
width: 50px ;
height: 8px !important;
border: none !important;
padding: 0 !important;
}

.custom-carousel .carousel-indicators button {

background: #4E4F56 !important;
color: black !important;
width: 50px ;
height: 8px !important;
border: none !important;
padding: 0 !important;
}


.custom-carousel .carousel-indicators {
bottom: -50px !important;


}



.prev1-buttons{

 border-radius: 8px !important;
  background: #4E4F56 !important;
color: black;
  width: 52px !important;
  height: 7px !important;
  
  border: none !important;
  padding: 0 !important;
  }


.next1-buttons{


  border-radius: 8px !important;
  background: linear-gradient(95.21deg, #406282 13.07%, #FFB74D 112.55%) !important;
  color: black !important;
  width: 26px !important;
  height: 7px !important;

  border: none !important;
  padding: 0 !important;
  }


.prev-buttons, .next-buttons:hover{

  border-radius: 50% !important;
  background: #4E4F56 !important;
color: black;
  width: 40px !important;
  height: 40px !important;
  justify-content: center !important;
  border: none !important;
  padding: 0 !important;
  }

.next-buttons, .prev-buttons:hover{

  border-radius: 50% !important;
  background: linear-gradient(95.21deg, #406282 13.07%, #FFB74D 112.55%) !important;
  color: black !important;
  width: 40px !important;
  height: 40px !important;
  justify-content: center !important;
  border: none !important;
  padding: 0 !important;
  }
.idea{
 margin-left: -60px;
}

.big-gap .row{
  --bs-gutter-x: 9rem !important;
}

.footer-arrow{
 
  transform:rotate(70deg) scaleY(-1)

}
.card-container {
  display: flex;
  flex-direction: column;
}

.card-container .card-img {
  height: 100%;
}

.gradient-text {
  color: transparent !important; 
  background: linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important; /* Ensure this property is also set without the vendor prefix */
  -webkit-text-fill-color: transparent !important;
font-weight: 700 !important;
  line-height: 70.24px;
  letter-spacing: 0.005em;
}

.gradient-text-underline {
position: relative;
display: inline;
color: transparent;
background: linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%);
-webkit-background-clip: text;
background-clip: text;
-webkit-text-fill-color: transparent;
text-decoration: none;
}

.gradient-text-underline::after {
content: '';
position: absolute;
left: 0;
right: 0;
width: 100%;
bottom: 0;
border-bottom: 2px solid;
border-image: linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%) 1;
}




.gradient-text-change {
transition: opacity 0.5s ease-in-out;
opacity: 0;
animation: fadeIn 0.5s ease forwards;
margin-top: 3rem;
/* Additional gradient styles here */
}

@keyframes fadeIn {
from { opacity: 0; }
to { opacity: 1; }
}


.cursor {
opacity: 1;
animation: blink 1s step-end infinite;
}

@keyframes blink {
50% {
  opacity: 0;
}
}
.team .row{
--bs-gutter-x: 1rem !important;
}

.gradient-patch-1{

  background: radial-gradient(circle, rgba(255, 183, 77, 0.4) 0%, rgba(54, 69, 76, 0) 60%);
  width: 239px;
  height: 243px;
  position: absolute;
  top: 50px;
  right: 0px;
  
  filter: blur(50px) ;

}
.gradient-patch-2{
background: radial-gradient(circle, rgba(255, 183, 77, 0.3) 0%, rgba(54, 69, 76, 0) 60%);
width: 288px;
height: 271px;
position: absolute;
top: 200px;
left: 30px;

filter: blur(80px) ;
}

.gradient-patch-3{
  background: radial-gradient(circle, rgba(255, 183, 77, 0.2) 0%, rgba(54, 69, 76, 0) 60%);
              width: 300px;
              height: 371px;
              position: absolute;
              top: 400px;
              left: 40%;
              
              filter: blur(50px)  ;
  }

.hero-section-2{
  margin-top: -170px;
}
.custom-carousel .carousel-indicators .active {
  background-color: linear-gradient(136.04deg, #6BA4D8 7.13%, #FFB74D 77.61%) !important;
  
  width: 10px !important;
  height: 10px !important;
  margin-top: 50px !important; 
}

.carousel .carousel-indicators button {

  width: 10px !important;
  height: 10px !important;
  margin-top: 50px !important;
}

.arrow-giphy{
  position: absolute;
  top: 32%;
  left: 62%;
  
}

.technology-giphy{
  position: absolute;
top: 0;
left: 20px;
}

.technology-giphy-2{
  position: absolute;
top: 70px;
right: 60px;


}

.technology-giphy-3{
  right:5%;
top: 70%;
}
.wave1{
  position: absolute;
  width: 600px;
  height: 600px;
  bottom: 0px;
  left: 0px;
  gap: 0px;
  opacity: 0.1px;
 
  


}
.wave{
  position: absolute;
  width: 600px;
  height: 600px;
  top: -45%;
  right: -35%;
  gap: 0px;
  opacity: 0.1px;


}
.wave2{
  position: absolute;
  width: 110px;
height: 270px;
right: 0px;
bottom: 0px;
gap: 0px;
border: 1px 0px 0px 0px;
opacity: 0px;


}

.wave3{
 
  width: 150px;
height: 150px;



}
.rate{
width: 130px;
}

.register-giphy{
  top: 80%;
  left:16%;
}

@media (max-width: 1950px) {
 
.box-bg{
  height: 850px !important;
 }
 .unique-img{
  height: 700px !important;
 }

 .hero-section-2{
  margin-top:-240px;
 }
 .hero-section-3{
  margin-top:70px;
 }
 .footer-arrow{
  top:-20%;
  right:8%;
  transform:rotate(75deg) scaleY(-1)

}
.register-giphy{
  top: 68%;
  left:11%;
}
}

@media (max-width: 1650px) {
 
 
   .hero-section-3{
    margin-top:20px;
   }
   .footer-arrow{
    top:-20%;
    right:10%;
    transform:rotate(75deg) scaleY(-1)
  
}
.register-giphy{
  top: 78%;
  left:13%;
}

  }
@media (max-width: 1400px) {
 
  .box-bg{
    height: 100% !important;
   }
   .unique-img{
    height: 100% !important;
   }
   .hero-section-3{
    margin-top: -50px;
   }
   .hero-section-2{
    margin-top:-150px;
   }
   .footer-arrow{
    top:-20%;
    right:100px;
    transform:rotate(120deg) scaleY(-1)
  
}
.register-giphy{
  top: 80%;
  left:16%;
}
  }

@media (max-width: 1030px) {
  .hero-section-2{
    margin-top: 50px !important;
  }
  .card-container .card-img {
    height: 700px;
  }

  .hero-section-3{
    margin-top: 100px;
   }

}

@media (max-width: 992px) {
 
  .idea{
    margin-left: -10px;
    
   }

   .idea h3{
    font-size: 18px;
   }
   .idea h4{
    font-size: 15px;
   }
   .rate{
    width: 100px;
     }
 
     .hero-section-3{
      margin-top: 200px;
     }
     .big-gap .row{
      --bs-gutter-x: 5rem !important;
    }
}


@media (max-width: 768px) {

  h1 {
    font-size: 28px  !important;
  }

  h2 {
    font-size: 24px  !important; 
  }
  
  h3 {
    font-size: 19px  !important;
   
   
  }
  h4 {
    font-size: 17px  !important;
  }
  h5 {
    font-size: 16px  !important;
  }
  h6{
    font-size: 14px  !important;
  }
  p {
    font-size: 12px  !important;
  }



  .gradient-text{
      line-height: 38px;
  }
  .unique-img{
    height: 750px !important;
   }
  .technology-giphy{
    width:45px;
    top: 50px;
  }
.technology-giphy-2{
    width:45px;
    top: 15px;
    right: 20px;
  }
  .arrow-giphy{
    width:100px;
    top: 25%;
    left: 76%;
 
  }
  .gradient-patch-1, .gradient-patch-2,.gradient-patch-3{
    width:100px;
    background: radial-gradient(circle, rgba(255, 183, 77, 0.4) 0%, rgba(54, 69, 76, 0) 60%);
  }
  .wave1,.wave{
   
    width: 250px;
    height: 250px;
  }

  .wave{
    top: -79%;
    right: -60%;
  }
  .wave2,.wave3{
  
    width: 40px;
height: 150px;

  }
  .register-giphy{
    top: 45%;
    left:26%;
    width:30%
  }
  .prev-buttons, .next-buttons{

   
    width: 30px !important;
    height: 30px !important;
    }
  
 .prev-buttons:hover{
 
   border-radius: 50% !important;
   background: #4E4F56 !important;
color: black;
   width: 30px !important;
   height: 30px !important;
   justify-content: center !important;
   border: none !important;
   padding: 0 !important;
   }

 .next-buttons:hover{
 
   border-radius: 50% !important;
   background: linear-gradient(95.21deg, #406282 13.07%, #FFB74D 112.55%) !important;
   color: black !important;
   width: 30px !important;
   height: 30px !important;
   justify-content: center !important;
   border: none !important;
   padding: 0 !important;
   }

   .box-bg{
    height: 550px !important;
   }
  .footer-arrow{
      top:-10%;
      right:10px;
      transform:rotate(90deg) scaleY(-1)
    
  }
  .hero-section-3{
    margin-top: 220px;
   }
   .big-gap .row{
    --bs-gutter-x: 4rem !important;
  }
}

@media (max-width: 670px) {
  .hero-section-2{
    margin-top: 100px !important;
  }
  .card-container .card-img {
    height: 770px;
  }


  .sw-logo{
    width:150px;
  }
  .technology-giphy-3{
    right:5%;
top: 88%;
width: 40px;
  }
}

@media (max-width: 570px) {
  .hero-section-2{
    margin-top: -100px !important;
  }
  .big-gap .row{
    --bs-gutter-x: 1rem !important;
  }
}